const env = process.env.REACT_APP_ENV;

const Config = {
  // service: 'http://10.244.23.105:10090',
  service: 'http://18.219.19.64:10090',
  resumeApi: '/mail/sendMail',
  maxUploadSize: 5210000
};

let devConfig = {};

if (env === 'test') {
  devConfig = {
    // service: 'http://10.244.23.105:10090'
    service: 'http://18.219.19.64:10090'
  };
}

export default Object.assign(Config, devConfig);
