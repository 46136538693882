import Home from '../pages/Home';
import Community from '../pages/Community';
import Defi from '../pages/Defi';
import Apply from '../pages/Apply';
import Join from '../pages/Join';
import Research from '../pages/Research';

export const routeList = [
  {
    title: '首页',
    key: 'home',
    comp: Home
  },
  {
    title: '社区治理（DAO）',
    key: 'community',
    comp: Community
  },
  {
    title: 'DeFi技术',
    key: 'defi',
    comp: Defi
  },
  {
    title: 'DeFi应用领域及拓展',
    key: 'apply',
    comp: Apply
  },
  // {
  //   title: '研究人才',
  //   key: 'research',
  //   comp: Research
  // },
  {
    title: '加入我们',
    key: 'join',
    comp: Join
  }
];
