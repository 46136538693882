import SectionTitle from '../../components/SectionTitle';
import com_icon_1 from '../../assets/images/com_icon_1.png';
import com_icon_2 from '../../assets/images/com_icon_2.png';
import com_icon_3 from '../../assets/images/com_icon_3.png';
import com_left_img from '../../assets/images/com_left_img.png';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { languageMaps } from '../../utils/constant';

const bottomIcons = [com_icon_1, com_icon_2, com_icon_3];
export default ({ isMobile }) => {
  const { t, i18n } = useTranslation();
  const textList = t('community.textList', { returnObjects: true });
  const bottomTextList = t('community.bottomList', { returnObjects: true }) || [];
  const bottomList = bottomTextList.map((item, i) => ({ ...item, icon: bottomIcons[i] }));

  return (
    <div
      className={classNames({ 'community-wrap': !isMobile, 'normal-content': true, 'community-wrap-mobile': isMobile })}
    >
      {isMobile && <SectionTitle isMobile={isMobile} title={t('community.title')} desc={t('community.subTitle')} />}
      <div className="community-content">
        <div className="community-left">
          <img className="community-img" src={com_left_img} alt="" />
        </div>
        <div className="community-right">
          {!isMobile && <SectionTitle title={t('community.title')} desc={t('community.subTitle')} />}
          {textList.map((item, i) => (
            <div className="text-item" key={i}>
              <div className="text-item-title">{item.title}</div>
              <div className="text-item-desc">
                <div>{item.desc}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-bar">
        {bottomList.map(item => {
          return (
            <div key={item.text} style={isMobile && i18n.language === languageMaps.us ? { flex: 1 } : {}}>
              <img className="bottom-bar-icon" src={item.icon} />
              <span className="bottom-bar-text">{item.text}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
