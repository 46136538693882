import './style.scss';
import SectionTitle from '../../components/SectionTitle';
import { Form, Input, Spin, Button, message } from 'antd';
import { useRef, useState } from 'react';
import file_reload from '../../assets/images/img_file_reload.svg';
import IconFile from '../../assets/images/file.svg';
import IconUpload from '../../assets/images/upload_cloud.png';
import Config from '../../config';
import axios from 'axios';
import epi_logo from '../../assets/images/epi_logo.png';
import gkd_logo from '../../assets/images/gkd_logo.png';
import gkd_logo_en from '../../assets/images/gkd_logo_en.png';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { defaultLanguage } from '../../utils/constant';

export default ({ isMobile }) => {
  const [form] = Form.useForm();
  const uploadRef = useRef();
  const file = Form.useWatch('resume', form);
  const [loading, setLoading] = useState(false); // 加载状态
  const { t, i18n } = useTranslation();
  const isEn = i18n.language === defaultLanguage.key;
  /*
   * 表单提交
   */
  const submitForm = () => {
    const API_ADDR = `${Config.service}${Config.resumeApi}`;
    const params = new FormData();
    const fileValue = form.getFieldsValue(true);
    params.append('name', fileValue.name);
    params.append('tel', fileValue.tel);
    // params.append('type', fileValue.type[0]);
    params.append('email', fileValue.email);
    params.append('file', fileValue.resume.value);
    axios({
      method: 'POST',
      url: API_ADDR,
      data: params,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      timeout: 60000
    })
      .then(res => {
        if (res.data.code === 0) {
          uploadSuccess();
        } else {
          uploadFail();
        }
        setLoading(false);
      })
      .catch(err => {
        uploadFail();
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /*
   * 提交
   */
  const onFinish = values => {
    setLoading(true);
    submitForm();
  };

  /*
   * 删除文件
   */
  const deleteFile = () => {
    form.resetFields(['resume']);
  };

  /*
   * 重置表单
   */
  const resetForm = () => {
    form.resetFields();
  };

  /*
   * 提交成功
   */
  const uploadSuccess = () => {
    resetForm();
    message.success(t('join.sendSuccess'));
  };

  /*
   * 提交失败
   */
  const uploadFail = () => {
    message.error(t('join.sendErr'));
  };

  /*
   * 文件校验
   */
  const checkResume = (_, value) => {
    if (!value) {
      return Promise.reject(t('join.resumeEmpty'));
    } else if (value?.value?.size > Config.maxUploadSize) {
      return Promise.reject(new Error(t('join.resumeErr')));
    }
    return Promise.resolve();
  };

  /*
   * 上传组件
   */
  const ResumeFile = ({ value = {}, onChange, isMobile }) => {
    const triggerValue = changeValue => {
      onChange?.({ value: changeValue });
    };

    // 文件变更
    const fileChange = e => {
      deleteFile();
      triggerValue(e.target.files[0]);
    };
    return (
      <div
        className="resume"
        onClick={() => {
          uploadRef.current.click();
        }}
      >
        <input
          ref={uploadRef}
          className="select"
          type="file"
          name="resume-file"
          accept=".png,.pdf,jpg"
          multiple={false}
          onChange={e => fileChange(e)}
        />
        <div className="resume-img-wrap">
          <img className="resume-img" src={file?.value ? IconFile : IconUpload} alt="upload" />
        </div>

        {file?.value ? (
          <div className="resume-file">
            <div className="file-name">{file?.value.name}</div>
            <div className="file-desc">
              <img className="re-icon" src={file_reload} alt={t('join.retry')} />
              <span>{t('join.retry')}</span>
            </div>
          </div>
        ) : isMobile ? (
          <>
            <div className="resume-title">{t('join.clickUpload')}</div>
            <div className="resume-desc">{t('join.fileDesc')}</div>
          </>
        ) : (
          <div className="resume-info">
            <div className="resume-title">{t('join.clickUpload')}</div>
            <div className="resume-desc">{t('join.fileDesc')}</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={classNames({ 'join-wrap': !isMobile, 'normal-content': true, 'join-wrap-mobile': isMobile })}>
      <SectionTitle title={t('join.title')} isMobile={isMobile} />
      <div className="join-content">
        <Spin tip="load..." spinning={loading}>
          <div className="join-card">
            <Form autoComplete="off" layout="vertical" requiredMark={false} form={form} onFinish={onFinish}>
              <div className="flex join-form">
                <div className="left">
                  <Form.Item
                    name="name"
                    label={t('join.name')}
                    rules={[{ required: true, max: 30, message: t('join.nameMsg') }]}
                  >
                    <Input placeholder={t('join.nameMsg')} />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label={t('join.email')}
                    rules={[{ required: true, max: 30, message: t('join.emailMsg') }]}
                  >
                    <Input placeholder={t('join.emailMsg')} />
                  </Form.Item>
                  <Form.Item name="tel" label={t('join.tel')} rules={[{ required: true, message: t('join.telMsg') }]}>
                    <Input placeholder={t('join.telMsg')} />
                  </Form.Item>
                </div>
                <div className="right">
                  <Form.Item name="resume" label={t('join.resume')} rules={[{ validator: checkResume }]}>
                    <ResumeFile isMobile={isMobile} />
                  </Form.Item>
                  <Button className="check-btn" type="primary" htmlType="submit" disabled={loading}>
                    {t('join.submit')}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Spin>
        {!isMobile && (
          <div>
            <div className="flex flex-between">
              <div>
                <img src={epi_logo} alt="logo" className="logo" />
                <img src={isEn ? gkd_logo_en : gkd_logo} alt="logo" className="logo" />
              </div>
              <div className="flex">
                <div className="address-item">
                  <div className="bottom-label">E-mail:</div>
                  <div className="bottom-value">epi@ust.hk</div>
                </div>
                <div className="address-item">
                  <div className="bottom-label">{t('join.bottomAdd')}:</div>
                  <div className="bottom-value">{t('join.address')}</div>
                </div>
              </div>
            </div>
            {/*<div className="bottom-line"></div>*/}
            {/*<div className="copy-right">©2014-2034 Macromedia, Inc. All rights reserved.</div>*/}
          </div>
        )}
      </div>
    </div>
  );
};
