import './style.scss';
import SectionTitle from '../../components/SectionTitle';
import defi_card_1 from '../../assets/images/defi_card_1.png';
import defi_card_2 from '../../assets/images/defi_card_2.png';
import defi_card_3 from '../../assets/images/defi_card_3.png';
import defi_card_4 from '../../assets/images/defi_card_4.png';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const cardIconList = [
  {
    icon: defi_card_1
  },
  {
    icon: defi_card_2
  },
  {
    icon: defi_card_3
  },
  {
    icon: defi_card_4,
    key: 'last'
  }
];

const Card = ({ item, isMobile }) => (
  <div className="card-wrap">
    {isMobile ? (
      <>
        <div>
          <img className={item.key === 'last' ? 'card-img card-img-last' : 'card-img'} src={item.icon} alt="" />
        </div>
        <div>
          <div className="card-title">{item.title}</div>
          <div className="card-desc">{item.desc}</div>
        </div>
      </>
    ) : (
      <>
        <div className="card-title">{item.title}</div>
        <div className="card-desc">{item.desc}</div>
        <img className={item.key === 'last' ? 'card-img card-img-last' : 'card-img'} src={item.icon} alt="" />
      </>
    )}
  </div>
);

export default ({ isMobile }) => {
  const { t } = useTranslation();
  const cardTextList = t('defi.cardList', { returnObjects: true }) || [];
  const cardList = cardTextList.map((item, i) => ({ ...item, ...cardIconList[i] }));
  return (
    <div className={classNames({ 'defi-wrap': !isMobile, 'normal-content': true, 'defi-wrap-mobile': isMobile })}>
      <SectionTitle isMobile={isMobile} title={t('defi.title')} desc={t('defi.subTitle')} />
      <div className="card-content">
        {cardList.map(item => {
          return <Card isMobile={isMobile} item={item} key={item.title} />;
        })}
      </div>
    </div>
  );
};
