import classNames from 'classnames';
import './style.scss';
import { useEffect, useState } from 'react';
import getFullPageApi from '../../utils/getFullPageApi';
import { routeList } from '../../utils/route';
import getUrlParam from '../../utils/getUrlParam';
import changeUrl from '../../utils/changeUrl';
import m_menu_icon from '../../assets/images/m_menu_icon.png';
import { Drawer } from 'antd';
import m_close from '../../assets/images/m_close.png';
import { useTranslation } from 'react-i18next';

const MenuItem = ({ item, handleClick, activeKey }) => {
  const { title, key } = item;
  const { t } = useTranslation();
  return (
    <div
      className={classNames({ 'menu-item': true, 'menu-item-active': item.key === activeKey })}
      onClick={() => handleClick && handleClick(item)}
    >
      {t(`menu.${key}`)}
    </div>
  );
};

export default ({ isMobile, count }) => {
  const [activeKey, changeKey] = useState('home');
  const [isOpen, open] = useState(false);
  useEffect(() => {
    const key = getUrlParam('key');
    changeKey(key);
    const n = routeList.findIndex(m => m.key === key);
    getFullPageApi().moveTo(n + 1);
  }, []);

  useEffect(() => {
    const key = getUrlParam('key');
    if (key !== activeKey) {
      changeKey(key);
    }
  }, [count]);

  const onMenuClick = item => {
    changeKey(item.key);
    changeUrl(item.key);
    const n = routeList.findIndex(m => m.key === item.key);
    getFullPageApi().moveTo(n + 1);
    open(false);
  };

  const menuContent = (
    <div className={classNames({ 'menu-list': !isMobile, 'menu-list-mobile': isMobile })}>
      {isMobile && (
        <div className="text-right" style={{ paddingRight: 15 }}>
          <img src={m_close} alt="close" className="m-close" onClick={() => open(false)} />
        </div>
      )}
      {routeList.map(item => (
        <MenuItem key={item.key} item={item} handleClick={onMenuClick} activeKey={activeKey} />
      ))}
    </div>
  );
  return (
    <div className={classNames({ 'menu-wrap': !isMobile, 'menu-wrap-mobile': isMobile })}>
      {isMobile && <img src={m_menu_icon} className="menu-icon" onClick={() => open(!isOpen)} />}
      {isMobile ? (
        <Drawer
          title={null}
          closable={false}
          onClose={() => open(false)}
          mask={false}
          width={`${159 / 16}rem`}
          open={isOpen}
          bodyStyle={{ padding: '12px 0 0' }}
          className="m-epi-menu"
        >
          {menuContent}
        </Drawer>
      ) : (
        menuContent
      )}
    </div>
  );
};
