import ReactFullpage from '@fullpage/react-fullpage';
import Header from './components/Header';
import { routeList } from './utils/route';

import './App.scss';
import changeUrl from './utils/changeUrl';
import { useEffect, useState } from 'react';
const DefaultComp = () => <div />;

// 原始移动端尺寸
const remBasicWidth = 375;
// 设计图根节点基准字体
const remBasicFont = 16;
// 认定为移动端的尺寸
const mobileOnly = 1080;

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [count, setCount] = useState(0);
  useEffect(() => {
    const handleFontSize = () => {
      const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
      if (htmlWidth <= mobileOnly) {
        setIsMobile(true);
        const htmlDom = document.getElementsByTagName('html')[0];
        htmlDom.style.fontSize = (htmlWidth * remBasicFont) / remBasicWidth + 'px';
      } else if (isMobile) {
        setIsMobile(false);
      }
    };
    handleFontSize();
    window.addEventListener('resize', handleFontSize, false);
    return () => {
      window.removeEventListener('resize', handleFontSize, false);
    };
  }, [isMobile]);

  return (
    <div>
      <Header isMobile={isMobile} count={count} />
      <ReactFullpage
        scrollingSpeed={1000} /* Options here */
        navigation={!isMobile}
        onLeave={(origin, destination) => {
          const target = routeList[destination.index];
          target && changeUrl(target.key);
          target && setCount(count + 1);
        }}
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              {routeList.map((item, index) => {
                const Comp = item.comp || DefaultComp;
                return (
                  <div key={item.key} className={`section ${item.key}-page-wrap`}>
                    <Comp isMobile={isMobile} />
                  </div>
                );
              })}
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </div>
  );
}

export default App;
