import './style.scss';
import SectionTitle from '../../components/SectionTitle';
import defi_apply_icon_1 from '../../assets/images/defi_apply_icon_1.png';
import defi_apply_icon_2 from '../../assets/images/defi_apply_icon_2.png';
import defi_apply_icon_3 from '../../assets/images/defi_apply_icon_3.png';
import defi_apply_icon_4 from '../../assets/images/defi_apply_icon_4.png';
import defi_apply_icon_5 from '../../assets/images/defi_apply_icon_5.png';
import defi_apply_icon_6 from '../../assets/images/defi_apply_icon_6.png';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const cardIconList = [
  {
    icon: defi_apply_icon_1
  },
  {
    icon: defi_apply_icon_2
  },
  {
    icon: defi_apply_icon_3
  },
  {
    icon: defi_apply_icon_4
  },
  {
    icon: defi_apply_icon_5
  },
  {
    icon: defi_apply_icon_6
  }
];

const Card = ({ item }) => (
  <div className="box-wrap">
    <img className="box-img" src={item.icon} alt="" />
    <div className="box-title">{item.title}</div>
  </div>
);

export default ({ isMobile }) => {
  const { t } = useTranslation();
  const cardTextList = t('apply.cardList', { returnObjects: true }) || [];
  const cardList = cardTextList.map((item, i) => ({ ...item, ...cardIconList[i] }));
  return (
    <div
      className={classNames({
        'apply-wrap': !isMobile,
        'normal-content': true,
        'apply-wrap-mobile': isMobile,
        'flex-col': true
      })}
    >
      <SectionTitle isMobile={isMobile} title={t('apply.title')} desc={t('apply.subTitle')} />
      {!isMobile ? (
        <>
          <div className="box-content">
            {cardList.slice(0, 3).map(item => {
              return <Card item={item} key={item.title} />;
            })}
          </div>
          <div className="box-content">
            {cardList.slice(3).map(item => {
              return <Card item={item} key={item.title} />;
            })}
          </div>
        </>
      ) : (
        <div className="box-content">
          {cardList.map(item => {
            return <Card item={item} key={item.title} />;
          })}
        </div>
      )}
    </div>
  );
};
