import './style.scss';
import classNames from 'classnames';

export default props => {
  const { title, desc, isMobile } = props;
  return (
    <div className={classNames({ 'section-title-wrap': !isMobile, 'section-title-wrap-mobile': isMobile })}>
      <div className="section-title">{title}</div>
      <div className="section-bar" />
      {desc && <div className="section-desc">{desc}</div>}
    </div>
  );
};
