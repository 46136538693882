import classNames from 'classnames';
import epi_text from '../../assets/images/epi_text.png';
import '../../assets/css/animation.scss';
import { useTranslation } from 'react-i18next';

import './style.scss';

export default ({ isMobile }) => {
  const { t } = useTranslation();
  const textList = t('home.banner', { returnObjects: true }) || [];
  return (
    <div className={classNames({ 'home-wrap': !isMobile, 'normal-content': true, 'home-wrap-mobile': isMobile })}>
      <img className="epi-image" src={epi_text} alt="" />
      <div className="epi-text">
        {textList.map((item, i) => {
          return <div key={i}>{item}</div>;
        })}
      </div>
    </div>
  );
};
