export const languageMaps = {
  cn: 'zh-CN',
  tw: 'zh-TW',
  us: 'en-US'
};

export const defaultLanguage = {
  key: languageMaps.us,
  title: 'En',
  label: 'English',
  languageFontRate: 0.7
};
export const languageList = [
  {
    key: languageMaps.cn,
    title: '简',
    label: '简体中文',
    languageFontRate: 1
  },
  {
    key: languageMaps.tw,
    title: '繁',
    label: '繁体中文',
    languageFontRate: 1
  },
  defaultLanguage
];
