import Menu from '../Menu';
import epi_logo from '../../assets/images/epi_logo.png';
import gkd_logo from '../../assets/images/gkd_logo.png';
import gkd_logo_en from '../../assets/images/gkd_logo_en.png';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { languageList, defaultLanguage } from '../../utils/constant';

import './style.scss';
import { useEffect, useState } from 'react';

export default ({ isMobile, count }) => {
  const [isOpen, setOpen] = useState(false);
  const { i18n } = useTranslation();
  const getTitle = key => {
    const item = languageList.find(item => item.key === key);
    return item ? item.title : defaultLanguage.title;
  };
  const [title = getTitle(i18n.language), setTitle] = useState();
  const isEn = title === defaultLanguage.title;
  useEffect(() => {
    const item = languageList.find(item => item.title === title) || defaultLanguage;
    document.documentElement.style.setProperty(`--languageFontRate`, item.languageFontRate);
  }, [title]);
  const handleChangeLanguage = val => {
    setOpen(false);
    setTitle(getTitle(val.key));
    i18n.changeLanguage(val.key);
  };
  return (
    <div className={classNames({ 'header-wrap': !isMobile, 'header-wrap-mobile': isMobile })}>
      <img src={epi_logo} alt="logo" className="logo" />
      <img src={isEn ? gkd_logo_en : gkd_logo} alt="logo" className="logo logo-gkd" />
      <Menu isMobile={isMobile} count={count} />
      <Dropdown
        placement="bottom"
        menu={{ items: languageList, selectedKeys: i18n.language, onClick: handleChangeLanguage }}
        overlayClassName="language-select-menu"
        trigger={isMobile ? ['click'] : ['hover']}
        open={isOpen}
        onOpenChange={isOpen => setOpen(isOpen)}
      >
        <Button type="primary" className="language-select">
          {title}
          <DownOutlined className="select-arrow" />
        </Button>
      </Dropdown>
    </div>
  );
};
